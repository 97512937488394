import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AccordionComponentMagazine from './AccordionComponent/AccordionComponent';

function App() {
  return (
    <div>
      {/* <AiChat/> */}
      {/* <DynamicDropdown/> */}
      {/* <Create/> */}
      <AccordionComponentMagazine />
    </div>
   
  );
}

export default App;
