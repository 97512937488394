import React, { useState } from 'react';
import { Accordion, Card, ListGroup } from 'react-bootstrap';
import { BsNewspaper } from "react-icons/bs";
import { CgNotes } from 'react-icons/cg';
import { FaRegBookmark } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { MdArrowDropDown, MdOutlineAddBusiness, MdOutlineClose, MdSearch } from 'react-icons/md';
import { IoBagHandle, IoHome } from "react-icons/io5";
import './AccordionComponent.css';
import homehead from "../Asstes/Frame 1000005557.png";
import BsMenuButtonWideFill from "../Asstes/BsMenuButtonWideFill.png";
import tabler_letter from "../Asstes/tabler_letter-k.png";
import Marker from "../Asstes/Frame.png";
import adOne from "../Asstes/ad1.png";
import womenone from "../Asstes/womenone.png";
import womentwo from "../Asstes/womentwo.png";
import courtone from "../Asstes/courtone.png";
import courttwo from "../Asstes/courttwo.png";
import contentPix from "../Asstes/Page 1 1.png";
import contentPixCovo from "../Asstes/AA1oVSqq 1.png";
import Link from "../Asstes/mdi_link-variant.png";
import Pencil from "../Asstes/solar_pen-2-bold.png";
import logo from "../Asstes/UEDUCATE LOGO JUNE 26 UPDATED-01 1.png"
import profile from "../Asstes/Ellipse 642.png"
import { BiCommentDetail, BiSolidBookmarkStar, BiSolidLike } from 'react-icons/bi';

const AccordionComponentMagazine = () => {
    const [activeKey, setActiveKey] = useState('0');
    const [subActiveKey, setSubActiveKey] = useState('0-0');

    const handleAccordionSelect = (eventKey) => {
        if (activeKey === eventKey && eventKey !== '0') {
            setActiveKey('0');
        } else {
            setActiveKey(eventKey);
        }
    };

    const handleSubAccordionSelect = (eventKey) => {
        setSubActiveKey(eventKey);
    };

    const getItemStyle = (key) => {
        return activeKey === key || (activeKey === '0' && key === '0')
            ? { backgroundColor: '#1E4E66', color: '#fff' }
            : {};
    };

    const option = [
        { label: "English", value: "English" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu", value: "Telugu" },
        { label: "Hindi", value: "Hindi" },
        { label: "Marathi", value: "Marathi" },
        { label: "Urdu", value: "Urdu" },
        { label: "Gujarati", value: "Gujarati" },
        { label: "Arabic", value: "Arabic" },
        { label: "French", value: "French" },
        { label: "Korean", value: "Korean" },
        { label: "Spanish", value: "Spanish" },
        { label: "German", value: "German" },
    ];


const [mainList, setMainList] = useState(true);
const [notesView, setNotesView] = useState(false);
const [bookMark, setBookMark] = useState(false);

    return (
        <>
            <div className='d-flex justify-content-between pt-2 px-3 border_bottom'>
                <div><img src={logo} alt='' /></div>
                <div><span className='theweek'>THE WEEK</span><span className='thebest_contentw'>The Best of the U.S. and International Media</span></div>
                <div><img src={profile} alt='' className='pe-2' /> <span className='thebest_content'>Prakash Venkat <MdArrowDropDown size={"2rem"} /></span></div>
            </div>
            <div className='row p-2 w-100 height_overall'>
                {mainList && (
                <div className='col-md-3 shadow bg-body rounded'>
                    <div className='card_buzz'>
                        <ListGroup variant="flush">
                            <ListGroup.Item
                                style={getItemStyle('0')}
                                onClick={() => handleAccordionSelect('0')}
                            >
                                <span className='d-flex align-items-center gap-2'><IoHome /> Home</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={getItemStyle('1')}
                                onClick={() => handleAccordionSelect('1')}
                            >
                                <span className='d-flex align-items-center gap-2'><BsNewspaper /> News</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={getItemStyle('2')}
                                onClick={() => handleAccordionSelect('2')}
                            >
                                <span className='d-flex align-items-center gap-2'><BiSolidBookmarkStar /> Arts</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={getItemStyle('3')}
                                onClick={() => handleAccordionSelect('3')}
                            >
                                <span className='d-flex align-items-center gap-2'><IoBagHandle /> Leisure</span>
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={getItemStyle('4')}
                                onClick={() => handleAccordionSelect('4')}
                            >
                                <span className='d-flex align-items-center gap-2'><MdOutlineAddBusiness /> Business</span>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div>
                        <img src={adOne} alt='ads' style={{width:"385px"}}/>
                    </div>
                    <div>
                        <img src={adOne} alt='commerical ad' style={{width:"385px"}}/>
                    </div>
                </div>
            )}
            {bookMark && (
                <div className='col-md-3 shadow bg-body rounded'>
                    <div className='noteCradView'>
                        <div className='NotesGg'>
                            <div className='notesGG'>
                            <FaRegBookmark/> &nbsp;&nbsp;&nbsp;
                            <span style={{
                                fontSize: "17px",
                                fontWeight: "500",
                            }}>
                                BookMark
                            </span>
                            </div>
                            <div>
                            <MdOutlineClose className='pointer' onClick={()=>{
                                setMainList(true)
                                setNotesView(false)
                                setBookMark(false)
                            }}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {notesView && (
                <div className='col-md-3 shadow bg-body rounded'>
                    <div className='noteCradView'>
                        <div className='NotesGg'>
                            <div className='notesGG'>
                            <CgNotes/> &nbsp;&nbsp;&nbsp;
                            <span style={{
                                fontSize: "17px",
                                fontWeight: "500",
                            }}>
                                Notes
                            </span>
                            </div>
                            <div>
                            <MdOutlineClose className='pointer' onClick={()=>{
                                setMainList(true)
                                setNotesView(false)
                                setBookMark(false)
                            }}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
                <div className='col-md-9 full_align'>
                    <div className='d-flex flex-row justify-content-between align-items-center py-2 px-4'>
                        <div className='d-flex gap-4 ps-2'><img src={BsMenuButtonWideFill} alt='icon' /> <MdSearch size={23} />
                            <span className='subheadFontWeek'>THE WEEK August 30, 2024</span>
                        </div>
                        <div className='d-flex align-items-center gap-4 pe-2'>
                            <img src={tabler_letter} alt='icon' className='pointer'/>
                            <FaRegBookmark size={23} className='pointer'onClick={()=>{
                                setMainList(false)
                                setNotesView(false)
                                setBookMark(true)
                            }}/>
                            <CgNotes size={23} onClick={()=>{
                                setMainList(false)
                                setNotesView(true)
                                setBookMark(false)
                            }}className='pointer'/>
                            <img src={Marker} style={{ width: '22px', height: '22px' }} alt='icon' className='pointer'/>
                            <img src={Link} style={{ width: '23px', height: '23px' }} alt='icon' className='pointer'/>
                            <img src={Pencil} style={{ width: '23px', height: '23px' }} alt='icon' className='pointer'/>
                            <IoMdSettings size={23} className='pointer'/>
                            <select
                                name="cars"
                                id="cars"
                                className="Header_Language pointer"
                            >
                                {option.map((car, index) => (
                                    <option key={index} value={car.value}>
                                        {car.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className='outline_border'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex align-items-center'>
                                    <img src={homehead} alt='' style={{ width: '430px', height: '430px' }} />
                                </div>
                                <div className='d-flex flex-column py-3 text-white gap-1'>
                                    <div className='contentWithimgHead mb-3'>THE WEEK</div>
                                    <div className='my-2 d-flex gap-2 flex-wrap'>
                                    <span className='editor'>Editor-in-chief:</span>
                                    <span className='bates'> Theunis Bates, </span>
                                    <span className='editor'>Editor-at-large: </span>
                                    <span className='bates'>William Falk</span>
                                    <span className='editor'>Executive editor:</span>
                                    <span className='bates'>Susan Caskie, </span>
                                    <span className='editor'>Managing editor:</span>
                                    <span className='bates'>Mark Gimein</span>
                                    <span className='editor'>Assistant managing editor: </span>
                                    <span className='bates'>Jay Wilkins, </span>
                                    <span className='editor'>Deputy editor/Arts:</span>
                                    <span className='bates'>Chris Mitchell</span>
                                    <span className='editor'>Deputy editor/News: </span>
                                    <span className='bates'>Chris Erikson, </span>
                                    <span className='editor'>Senior editors:</span>
                                    <span className='bates'>Isaac Guzmán,</span>
                                    <span className='bates'> Harold Maass, </span>
                                    <span className='bates'> Scott Meslow,</span>
                                    <span className='bates'> Rebecca Nathanson,</span>
                                    <span className='bates'>Dale Obbie,Zach Schonbrun,</span>
                                    <span className='bates'> Hallie Stiller,</span>
                                    <span className='editor'>Associate editor:</span>
                                    <span className='bates'> Emily Russell,</span>
                                    <span className='editor'>Art director:</span>
                                    <span className='bates'>Paul Crawford</span>
                                    <span className='editor'>Deputy art director:</span>
                                    <span className='bates'>Rosanna Bulian, </span>
                                    <span className='editor'>Photo editor: </span>
                                    <span className='bates'>Mark Rykoff</span>
                                    <span className='editor'>Copy editor:</span>
                                    <span className='bates'>Jane A. Halsey, </span>
                                    <span className='editor'>Research editors:</span>
                                    <span className='bates'>Allan Kew, Alex Maroño Porto,</span>
                                    <span className='editor'>Contributing editors:</span>
                                    <span className='bates'>Ryan Devlin, Bruno Maddox.</span>
                                    </div>
                                   
                                    <div className='mt-2 d-flex gap-2 flex-wrap'>
                                    <span className='editor'> SVP subscriptions media & events:</span>
                                    <span className='bates'> Sarah Rees</span>
                                    <span className='editor'>VP advertising:</span>
                                    <span className='bates'> Stevie Lee (stevie.lee@futurenet.com)</span>
                                    <span className='editor'>Account director:</span>
                                    <span className='bates'>Mary Gallagher (mary.gallagher@futurenet.com)</span>
                                    <span className='editor'>Direct response advertising:</span> 
                                    <span className='bates'>Anthony Smyth (anthony@smythps.com)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-4 px-4 accrodion_scroll'>
                        <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <span className='d-flex align-items-center gap-2'>
                                        <BsNewspaper /> News
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Accordion defaultActiveKey="0-0" activeKey={subActiveKey} onSelect={handleSubAccordionSelect}>
                                        <Accordion.Item eventKey="0-0">
                                            <Accordion.Header>Main Stories</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='d-flex flex-column gap-3'>
                                                    <div className='d-flex justify-content-center magiHeadfont'>
                                                        The Main Stories
                                                    </div>
                                                    <div className='d-flex justify-content-center magiSubfont'>
                                                        Energized Democrats rally for Harris at convention
                                                    </div>
                                                    <div className='magiQues'>
                                                        What happened
                                                    </div>
                                                    <div className='magiContent'>
                                                        Democrats this week hailed Vice President Kamala Harris as the candidate of change and optimism at the Democratic National Convention in Chicago, an event that showcased the party's dramatic surge in energy since President Biden withdrew as nominee. "There's an excitement on the ground that is hard to describe," said Gov. Roy Cooper of North Carolina, one of numerous Democrats who drew compari- sons to 2008, when Barack Obama made his first White House run. Barack and Michelle Obama galvanized the crowd with back-to-back speeches, in which they democrats this week hailed Vice President Kamala Harris as the candidate of change and optimism at the Democratic National Convention in Chicago, an event that showcased the party's dramatic surge in energy since President Biden withdrew as nominee. "There's an excitement on the ground that is hard to describe," said Gov. Roy Cooper of North Carolina, one of numerous Democrats who drew compari- sons to 2008, when Barack Obama made his first White House run. Barack and Michelle Obama galvanized the crowd with back-to-back speeches, in which they cast Donald Trump as a tired crank and the 59-year-old Harris as a ticket to a bold and hopeful future. "Something magical is in the air, isn't it?" said Michelle. "It's the contagious power of hope." Barack called Trump "a 78-year-old billionaire" endlessly "whining about his problems.
                                                    </div>

                                                    <div className='d-flex gap-3 justify-content-center'>
                                                        <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                            <img src={contentPix} alt="magazine" />
                                                            <span className='imageNamealt'>Harris and Biden: The torch has been passed.-</span>
                                                        </div>
                                                        <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                            <img src={contentPix} alt="magazine" />
                                                            <span className='imageNamealt'>Harris and Biden: The torch has been passed.-</span>
                                                        </div>
                                                    </div>

                                                    <div className='magiContent'>
                                                        Night 1 was capped by a speech from Biden, who grew visibly emotional as he was met by an extended, thunderous ovation and chants of "We love Joe!" He touted achievements such as capping insulin prices and passing a $1 trillion infrastructure bill. "America, I gave you my best," he said. "I made a lot of mistakes in my career. But I gave my best to you for 50 years." He praised Harris' tough- ness and "enormous integrity," and called on voters to carry her over the finish line. "We saved democracy in 2020," he said, "and now we must save it again in 2024." Harris, who was scheduled to address the convention after The Week went to press, trolled Trump on Night 2 by giving a speech in nearby Milwaukee, in the same arena where he accepted the GOP nomination last month. This campaign "is a fight for freedom," Harris said. "Like the freedom for a woman to make decisions about her own body." Trump assailed Harris on a campaign stop in Michigan, calling her a "lawless Marxist" who allowed "hordes of illegal alien criminals to stampede into our country." If she is elected, he said, "Kamala Harris will deliver crime,chaos, destruction, and death."
                                                    </div>

                                                        <div className='magiQues'>
                                                            What the columnists said
                                                        </div>

                                                        <div className='magiContent'>
                                                            emotion of the moment, the bittersweetness of the circumstances, could not be lost on anyone," said Jennifer Rubin in The Washington Post. After a remarkable term that "de- fied expectations," Biden had capped 50 years of public service by stepping down to defend our democracy. Now he faced "the last consequential speech" of his presidency, at an event he originally hoped to headline as nominee, and the bitter reality that "the future will be in others' hands."The least Democrats could have done "was show Biden true respect as he heads for the door," said Ingrid Jacques in USA Today. Instead, he was given a slot on the first night, and didn't take the stage until 11:25 p.m. Eastern time, long after many viewers tuned out. Basi- cally, party bosses did all they could to send Biden "out to pasture" and put distance between him and Harris. Biden deserved better. “ The future of the Democratic Party is female," said Michael Tomasky in The New Republic. You could hear it in the "thunderous applause" that greeted Rep. Alexandria Ocasio-Cortez, who called Trump a "two-bit union buster" and spoke of her working- class roots. It was there in the "rapturous" reception for Hillary Clinton, who spoke of the "glass ceiling" for women, and vowed.
                                                        </div>

                                                            <div className='d-flex justify-content-between'>
                                                                <div className='footerCont'>THE WEEK August 30, 2024</div>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    <div className='like'><BiSolidLike color={"#BB0F2C"} /><span>Like</span></div>
                                                                    <div className='like'><BiCommentDetail color={"#BB0F2C"} /><span>Comment</span></div>
                                                                </div>
                                                            </div>
                                                    
                                                    
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="0-1">
                                            <Accordion.Header>Controversy of the week</Accordion.Header>
                                            <Accordion.Body>
                                            <div className='d-flex flex-column gap-3'>
                                                <div className='d-flex justify-content-center magiHeadfont'>
                                                    Controversy of the week
                                                </div>
                                                <div className='d-flex magiSubfont'>
                                                    <span className='harris'>Harris:</span> Does her Economic plan make sense?
                                                </div>

                                                <div className='magiContent'>
                                                    "Sound politics doesn't always align with sound economics," said Felix Salmon in Axios. And Vice President Kamala Harris, it seems, is banking on the former. The Democratic nominee last week began setting out her eco- nomic agenda-one short on details but long on the kind of voter-pleasing promises that "elicit eye rolls from economists." She has a few sensible ideas, including raising the minimum wage and expanding the child tax credit from $2,000 to $3,600. In the name of fairness, she also wants to hike the corporate tax rate from 21 percent to 28 percent, and to offer an unprecedented $25,000 tax credit to first-time homebuyers. The worst of Harris' proposals, said Catherine Rampell in The Washington Post, is a ban on "price gouging on food and groceries." While it may be politically useful to blame "greedy" corporations for the runaway inflation of President Biden's first three years in office, "it's hard to exaggerate how bad" this is as economy policy. Whenever bureaucrats take over from the market in determining profit levels and prices, the result is inevitably "shortages, black markets, and hoarding"-think Venezuela today or America in the 1970s. As for the politics, when fighting a close election in which your opponent "claims you're a 'communist," is calling for Soviet-style price controls really a wise idea? Except, "she didn't say anything like that," said Paul Krugman in The New York Times. All Harris is calling for, her campaign clari- fied, are federal rules to ensure "that big corporations can't unfairly exploit consumers to run up excessive profits." To free-market zealots, this may seem like a slippery slope toward communism. But 38 states, including such hotbeds of Marxist thought as Alabama and Texas, already have anti-gouging laws that ban "excessive" price hikes on essentials such as food and fuel during disasters.
                                                </div>

                                                <div className='d-flex gap-3 justify-content-center'>
                                                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                        <img src={contentPixCovo} alt="magazine" />
                                                        <span className='imageNamealt'>Harris is taking aim at grocery store prices.</span>
                                                    </div>
                                                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                        <img src={contentPixCovo} alt="magazine" />
                                                        <span className='imageNamealt'>Harris is taking aim at grocery store prices.</span>
                                                    </div>
                                                </div>

                                                <div className='magiContent'>This is "simply consumer protection," said Jill Lawrence in The Bulwark. Harris wants to make life easier for parents, homebuyers, and every American squeezed by the wave of infla- tion unleashed by the pandemic. That's basic compassion, not a "Soviet plot." Maybe so, but Harris' plans are still economi- cally illiterate, said Josh Barro in The Atlantic. Any "Econ 101" student could tell you profit margins in the grocery business are famously tight-a meager 1.6 percent last year-a sign of a robustly competitive market that doesn't need state interven- tion. Or that gifting a $25,000 tax credit to first-time homebuyers will simply add $25,000 to the price of a first-time home. These are all "bad ideas," as is Donald Trump's "no-tax-on-tips" proposal that Harris has now shamelessly embraced. But "better this shame- lessness than the Biden campaign's complete flat-footedness on the cost-of-living issue," which was set to cost him re-election.</div>

                                                <div className='backcontentgreyCol'>
                                                    <div className='headecolconten'>
                                                        Only in America
                                                    </div>
                                                    <div className='subcintentcol ps-3'>
                                                        <li>Texas Attorney General Ken Paxton is threatening to sue the State Fair of Texas for banning guns. Organizers took the deci- sion to forbid firearms on their 277-acre Dallas showground af- ter a gunman opened fire inside a food court at last year's fair, wounding three people. Paxton, however, told organizers.</li>
                                                    </div>

                                                    <div className='headecolconten pt-2'>
                                                        In Other News
                                                    </div>

                                                    <div className='italicolCOnte'>RNC asks Supreme Court to block 41,000 Arizona voters </div>

                                                    <div className='subcintentcol'>
                                                        The Republican National Com mittee asked the Supreme Court this week to bar up to 41,000 registered Arizona voters from casting ballots in November. The RNC says these Arizonans- mostly service members, stu- dents, and Native Americans- failed to present "documentary proof of their citizenship before registering to vote.
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-between'>
                                                    <div className='footerCont'>THE WEEK August 30, 2024</div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='like'><BiSolidLike color={"#BB0F2C"} /><span>Like</span></div>
                                                        <div className='like'><BiCommentDetail color={"#BB0F2C"} /><span>Comment</span></div>
                                                    </div>
                                                </div>


                                            </div>  
                                            
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="0-2">
                                        <Accordion.Header>The U.S. at a glance</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='d-flex flex-column gap-3'>
                                                <div className='d-flex justify-content-center magiHeadfont'>
                                                    The U.S. at the glance...
                                                </div>

                                                <div className='d-flex magiSubfont'>
                                                    Kenosha, Wis.
                                                </div>

                                                <div className='magiContent'>
                                                    <span className='harri_s'>Trafficked killer:</span> A Wisconsin woman who argued she was justified in killing a man who'd sexually trafficked her was sentenced to 11 years in prison this week. In exchange for pleading guilty, 24-year- old Chrystul Kizer received a reduced charge of reckless homicide for shooting Randall Volar when she was 17. Volar was under investigation for child sex trafficking; police had found videos of victims, and Volar had been arrested but released without bail. After shooting Volar, Kizer set his home on fire and fled in his BMW. Wisconsin's Supreme Court ruled that she could argue her actions were justified under a state law that protects victims of trafficking, but Kenosha County District Attorney Mike Graveley maintained she orchestrated the murder to steal Volar's car. Kizer had messaged a friend about her plans, texting "I'm finna get a bmw." Judge David Wilk said that regardless of what Volar did, Kizer was "not permitted to be the instrument of his reckoning."
                                                </div>

                                                <div className='d-flex gap-3 justify-content-center'>
                                                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                        <img src={courtone} alt="magazine" />
                                                        <span className='imageNamealt'>Kizer in Court</span>
                                                    </div>
                                                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                        <img src={courttwo} alt="magazine" />
                                                        <span className='imageNamealt'>Kizer in Court</span>
                                                    </div>
                                                </div>

                                                <div className='d-flex magiSubfont'>
                                                    Oxford, Conn.                                                </div>

                                                <div className='magiContent'>
                                                    <span className='harri_s'>Devastating rains:</span> Flash floods battered parts of western Connecticut and Long Island, New York, this week, killing at least two people and leading to dramatic rescue operations. Water poured onto roads so quickly that many drivers were caught unaware. One woman in Oxford, Conn., was swept away by floodwaters as firefighters tried to save her, and another died in the racing water after she got out of her car and tried to hang on to a sign. Over 100 Connecticut residents were evacuated by search-and-rescue teams. Firefighters turned the long ladder on one of their trucks into a bridge to access 18 people trapped at an Oxford inn. They crawled one by one across the ladder over floodwaters as cars and debris crashed into the inn. About 10 inches of rain hit some towns, causing power outages, state park closures, and transit disruptions. Meteorologist Craig Ceecee called it a "once-in-1,000 year" event.
                                                </div>

                                                <div className='d-flex magiSubfont'>
                                                    New York City
                                                </div>

                                                <div className='magiContent'>
                                                    <span className='harri_s'>Resignation:</span> Columbia University President Minouche Shafik stepped down last week, after 13 months leading a school torn by accusations of antisemi- tism and student protests over the Israel- Hamas war. Shafik faced attacks from both sides of the ideological divide. She faced criticism on campus and from Congress over Columbia's Gaza solidar- ity encampment and frequent pro-Palestinian protests; three Columbia deans recently resigned over text messages in which they mocked complaints of antisemitism. But Shafik was also denounced by student activists and some faculty after calling in police to arrest students who had taken over a university building. A House committee found that, despite pledges of discipline, 18 of the 22 students arrested in the Hamilton Hall and in good standing.
                                                </div>

                                                <div className='d-flex gap-3 justify-content-center'>
                                                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                        <img src={womenone} alt="magazine" />
                                                        <span className='imageNamealt'>Shafik</span>
                                                    </div>
                                                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                                        <img src={womentwo} alt="magazine" />
                                                        <span className='imageNamealt'>Shafik</span>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-between'>
                                                    <div className='footerCont'>THE WEEK August 30, 2024</div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='like'><BiSolidLike color={"#BB0F2C"} /><span>Like</span></div>
                                                        <div className='like'><BiCommentDetail color={"#BB0F2C"} /><span>Comment</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <span className='d-flex align-items-center gap-2'>
                                    <BiSolidBookmarkStar /> Arts
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <span className='d-flex align-items-center gap-2'>
                                    <IoBagHandle /> Leisure
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <span className='d-flex align-items-center gap-2'>
                                    <MdOutlineAddBusiness /> Business
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>

            </div>
        </div >
        </>
    );
};

export default AccordionComponentMagazine